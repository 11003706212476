/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Box, Breadcrumbs, Button, CloseButton, Divider, Flex, Grid, LoadingOverlay, Modal, Paper, Tabs, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconExclamationCircle, IconId, IconMail, IconNotes, IconPhone, IconShieldCheckFilled, IconUser } from "@tabler/icons-react";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthApi } from "../../apis";
import { ProfileLayout } from "../../components/profile-layout/index";
import { MeForm } from "../../components/profile-layout/me-form";
import { ChangePasswordForm } from "../../components/profile-layout/password-change-form";
import { ChangePinForm } from "../../components/profile-layout/pin-change-form";
import { ImageUploadMe } from "../../components/upload/image-upload-me";
import { IAuth } from "../../interfaces/IAuth";
import { authMe } from "../../store/auth";
import { message } from "../../utils/message";

const inventationStatus = (val: string) => {
  switch (val) {
    case "DRAFT": {
      return (
        <Badge color="gray" size="md" radius="sm" variant="filled">
          Түр төлөв
        </Badge>
      );
    }
    case "VERIFIED": {
      return (
        <Badge color="violet" size="md" radius="sm" variant="filled">
          Бүртгэсэн
        </Badge>
      );
    }
    case "ACTIVE": {
      return (
        <Badge color="green" size="md" radius="sm" variant="filled">
          Идэвхтэй
        </Badge>
      );
    }
    case "IN_ACTIVE": {
      return (
        <Badge color="red" size="md" radius="sm" variant="filled">
          Идэвхгүй
        </Badge>
      );
    }

    case "INVITED": {
      return (
        <Badge color="cyan" size="md" radius="sm" variant="filled">
          Урьсан
        </Badge>
      );
    }
    default: {
      return (
        <Badge color="gray" size="md" radius="sm" variant="filled">
          Хоосон
        </Badge>
      );
    }
  }
};

const booleanStatus = (val: boolean) => {
  switch (val) {
    case true: {
      return (
        <Badge color="indigo" size="md" radius="sm" variant="filled">
          Тийм
        </Badge>
      );
    }
    case false: {
      return (
        <Badge color="gray" size="md" radius="sm" variant="filled">
          Үгүй
        </Badge>
      );
    }
    default: {
      return (
        <Badge color="gray" size="md" radius="sm" variant="outline">
          Хоосон
        </Badge>
      );
    }
  }
};

export function ProfileMe() {
  const { theme } = useStyles();
  const breadcrumbs = useBreadcrumb();
  const { user } = useSelector((state: { auth: IAuth | any }) => state.auth);
  const [activeTab, setActiveTab] = useState<any>("PROFILE");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalType, setModalType] = useState({
    type: "",
    message: "",
    visible: false,
  });
  const [passwordModal, setPasswordModal] = useState<any>([]);
  const previousSearch = useRef("");

  const me = async () => {
    const auth = await AuthApi.me();
    dispatch(authMe(auth));
  };

  const danVerify = useCallback(async (search: string) => {
    const authResult = new URLSearchParams(search);

    if (authResult.get("code") && authResult.get("expires")) {
      try {
        setLoading(true);
        await AuthApi.danVerify({
          code: authResult.get("code")!,
          state: authResult.get("state") || "",
          expires: authResult.get("expires")!,
        });

        await new Promise((resolve) => setTimeout(resolve, 1500));
        setModalType({ type: "success", message: "Таны бүртгэл амжилттай баталгаажлаа", visible: true });
        me();
      } catch (error: any) {
        setModalType({ type: "error", message: error.message || "Бүртгэл баталгаажаагүй дахин оролдоно уу!", visible: true });
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const handleDialogOk = () => {
    setModalType({ type: "", message: "", visible: false });
    navigate("/auth/profile/dan");
  };

  useEffect(() => {
    const verifyDan = async () => {
      const authResult = new URLSearchParams(location.search);

      if (location.search !== previousSearch.current && authResult.get("code") && authResult.get("expires")) {
        previousSearch.current = location.search;
        await danVerify(location.search);
      }
    };

    verifyDan();
  }, [location.search]);

  const changeAvatar = async (e: any) => {
    try {
      await AuthApi.updateAvatar({ avatar: e?.url });
      message.success("Хэрэглэгчийн зураг амжилттай солигдлоо");
    } catch (error: any) {
      message.error(error.message || "Алдаа гарлаа");
    }
  };

  const getUserDisplayName = (user: any) => {
    if (!user) return "-";
    const lastNameInitial = user.lastName ? user.lastName[0] + "." : "";
    return `${lastNameInitial}${user.firstName || ""}`;
  };

  return (
    <ProfileLayout>
      <PageLayout title="Миний мэдээлэл" subTitle={"Миний мэдээлэл"} breadcrumb={breadcrumbs} bgColor={theme.colors.gray[0]}>
        <Grid gutter="md">
          <Grid.Col span={{ sm: 12, md: 6, lg: 4, xl: 3 }}>
            <Box
              h="100%"
              sx={(theme) => ({
                border: `1px solid  ${theme.colors.gray[4]}`,
                borderRadius: theme.radius.md,
                backgroundColor: theme.white,
              })}
              px={20}
              py={16}>
              <Flex align="center" direction="column" gap="md" justify="center">
                <Box sx={{}}>
                  <ImageUploadMe value={user?.avatar} radius={100} size={87} onChange={(e) => changeAvatar(e)} />
                </Box>
                <Text fw={600} fz="xl" c="violet" tt={"capitalize"}>
                  {getUserDisplayName(user)}
                </Text>
                <Text fw={700} fz="sm" c="gray">
                  {user?.role?.name || ""}
                </Text>

                <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="Холбоо барих" />
                <Flex direction="column" gap="xs" justify="start" align="start" w="100%">
                  <Flex gap="sm">
                    <IconMail color={theme.colors.violet[6]} />
                    <Text fw={500} c="gray">
                      {user?.email || "-"}
                    </Text>
                  </Flex>
                  <Flex gap="sm" justify="start" align="start" w="100%">
                    <IconPhone color={theme.colors.violet[6]} />
                    <Text fw={500} c="gray">
                      {user?.phone || "-"}
                    </Text>
                  </Flex>
                  <Flex gap="sm" justify="start" align="start" w="100%">
                    <IconUser color={theme.colors.violet[6]} />
                    <Text fw={500} c="gray">
                      {user?.departmentUnit?.name || "-"}
                    </Text>
                  </Flex>
                  <Flex gap="sm" justify="start" align="start" w="100%">
                    <IconId color={theme.colors.violet[6]} />
                    <Text fw={500} c="gray">
                      {user?.employeeUnit?.name || "-"}
                    </Text>
                  </Flex>
                </Flex>
                <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="Төлөв" />
                <Flex direction="column" gap="xs" justify="start" align="start" w="100%">
                  <Text fw={500} c="gray">
                    Хэрэглэгчийн Төлөв:
                  </Text>
                  {inventationStatus(user?.userStatus)}
                  <Text fw={500} c="gray">
                    И-мэйл баталгаажилт:
                  </Text>
                  {booleanStatus(user?.isEmailVerified)}
                  <Text fw={500} c="gray">
                    Утас баталгаажилт:
                  </Text>
                  {booleanStatus(user?.isPhoneVerified)}
                  <Text fw={500} c="gray">
                    ДАН систем баталгаажилт:
                  </Text>
                  {booleanStatus(user?.isDanVerified)}
                </Flex>
              </Flex>
            </Box>
          </Grid.Col>
          <Grid.Col span={{ sm: 12, md: 6, lg: 7, xl: 9 }}>
            <Box
              h="100%"
              sx={(theme) => ({
                border: `1px solid  ${theme.colors.gray[4]}`,
                borderRadius: theme.radius.md,
                backgroundColor: theme.white,
              })}
              px={20}
              py={16}>
              <Tabs variant="pills" value={activeTab} onChange={(value: string | null) => setActiveTab(value)} h="100%">
                <Tabs.List>
                  <Tabs.Tab leftSection={<IconNotes color={activeTab === "PROFILE" ? "white" : "gray"} />} value="PROFILE">
                    <Text c={activeTab === "PROFILE" ? "" : "gray"}>Үндсэн мэдээлэл</Text>
                  </Tabs.Tab>
                </Tabs.List>

                <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="lg" label="ҮНДСЭН МЭДЭЭЛЭЛ" />

                <Tabs.Panel value="PROFILE">
                  <LoadingOverlay visible={loading} />
                  <MeForm changePassword={setPasswordModal} isDanVerified={user?.isDanVerified} />
                </Tabs.Panel>
              </Tabs>
            </Box>
          </Grid.Col>
          <Modal centered withCloseButton={false} opened={passwordModal[0]} onClose={() => setPasswordModal([])} padding={0}>
            <Paper withBorder>
              <PageLayoutMini
                title={passwordModal[1] === "PIN" ? (user?.hasPin ? "Пин код өөрчлөх" : "Пин код үүсгэх") : "Нууц үг солих"}
                extra={[<CloseButton onClick={() => setPasswordModal([])} key={0} />]}>
                {passwordModal[1] === "PIN" ? (
                  <ChangePinForm isEdit={user?.hasPin as boolean} onClose={() => setPasswordModal([])} />
                ) : (
                  <ChangePasswordForm onClose={() => setPasswordModal([])} />
                )}
              </PageLayoutMini>
            </Paper>
          </Modal>

          {/* Success dialog */}
          <Modal
            centered
            withCloseButton={false}
            opened={modalType.visible}
            size="sm"
            closeOnClickOutside={false}
            onClose={() => setModalType({ type: "", message: "", visible: false })}>
            <Box sx={(theme) => ({ padding: theme.spacing.md, display: "flex", flexDirection: "column", gap: 10, alignItems: "center" })}>
              {modalType.type === "success" ? (
                <IconShieldCheckFilled color={theme.colors.green[6]} size={56} />
              ) : modalType.type === "error" ? (
                <IconExclamationCircle color={theme.colors.red[6]} size={56} />
              ) : null}
              <Text c={theme.colors.dark[5]} size="md">
                {modalType.message}
              </Text>
              <Button variant="outline" onClick={() => handleDialogOk()} color={modalType.type === "success" ? "green" : "red"}>
                Ойлголоо
              </Button>
            </Box>
          </Modal>
        </Grid>
      </PageLayout>
    </ProfileLayout>
  );
}

type PageProps = {
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
  extra?: React.ReactNode[];
  children: React.ReactNode;
  breadcrumb?: { to?: string; label: string }[];
  bgColor?: string;
};

export function PageLayout({ title, subTitle, breadcrumb, bgColor = "white", extra = [], children }: PageProps) {
  const { classes } = useStyles();

  return (
    <div
      className={classes.pageLayout}
      style={{
        backgroundColor: bgColor,
      }}>
      {breadcrumb && <Breadcrumb data={breadcrumb} />}

      <div className={classes.titleLayout}>
        <div>
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.subTitle}>{subTitle}</p>
        </div>
        <div className={classes.extra}>{extra}</div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

export function PageLayoutMini({ title, subTitle, breadcrumb, bgColor = "white", extra = [], children }: PageProps) {
  const { classes } = useStyles();

  return (
    <div
      className={classes.pageLayoutMini}
      style={{
        backgroundColor: bgColor,
      }}>
      {breadcrumb && <Breadcrumb data={breadcrumb} />}

      <div className={classes.titleLayout}>
        <div>
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.subTitle}>{subTitle}</p>
        </div>
        <div className={classes.extra}>{extra}</div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

type Props = {
  data: { label: string; to?: string }[];
};

export function Breadcrumb({ data }: Props) {
  return (
    <Breadcrumbs separator="/">
      {data.map((item, index) => (
        <Fragment key={index}>{item.to ? <Link to={item.to}>{item.label}</Link> : <span>{item.label}</span>}</Fragment>
      ))}
    </Breadcrumbs>
  );
}

const useStyles = createStyles((theme) => ({
  pageLayout: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: theme.spacing.md,
    backgroundColor: theme.white,
    height: "100vh",
    paddingTop: 75,
  },
  pageLayoutMini: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: theme.spacing.md,
    backgroundColor: theme.white,
  },
  formLayout: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    backgroundColor: theme.white,
    height: "100%",
  },
  titleLayout: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  content: {
    display: "block",
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    margin: 0,
  },
  subTitle: {
    fontSize: 14,
    margin: 0,
    padding: 0,
    color: theme.colors.gray[6],
  },
  extra: {
    display: "flex",
    gap: 15,
  },
  pageFilter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: theme.colors.gray[0],
    padding: 8,
    borderRadius: 2,
  },
  sectionElement: {
    display: "flex",
    gap: 15,
  },
}));

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Миний мэдээлэл",
  },
];
