import { Grid, Paper, Text, ThemeIcon, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useSelector } from "react-redux";
import BackgroundPattern from "../assets/bg-pattern.png";
import { IAuth } from "../interfaces/IAuth";

export function GridCard({ data, setAction }: { data: any; setAction: React.Dispatch<React.SetStateAction<any[]>> }) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { classes } = useStyles({
    color: data.fromColor,
  });

  if (user?.myModules?.some((item: string) => item === data.module)) {
    return (
      <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
        <UnstyledButton
          w="100%"
          h="100%"
          onClick={() => {
            if (data.module === "FINANCE") {
              setAction(["finance", data]);
            } else window.location.href = data.url;
          }}
          className={classes.container}>
          <img src={BackgroundPattern} alt="" className={classes.background} />
          <Paper className={classes.card}>
            <ThemeIcon size="xl" radius={0} variant="gradient" gradient={{ deg: 0, from: data.fromColor, to: "#051139" }}>
              <data.icon size={28} stroke={1.5} />
            </ThemeIcon>
            <Text size="lg" c={"white"} fw={500} mt="md">
              {data.name}
            </Text>
            <Text size="sm" mt="sm" c="white">
              {data.description}
            </Text>
          </Paper>
        </UnstyledButton>
      </Grid.Col>
    );
  } else {
    return null;
  }
}

const useStyles = createStyles((theme, params: { color: string }) => ({
  card: {
    position: "relative",
    backgroundColor: "#ffffff09",
    cursor: "pointer",
    borderRadius: 0,
    border: 0,
    overflow: "hidden",
    transition: "transform 150ms ease, box-shadow 100ms ease",
    padding: theme.spacing.xl,
    paddingLeft: theme.spacing.xl,
    height: "100%",

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.05)",
    },

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: 6,
      backgroundImage: `linear-gradient(0, #051139, ${params.color})`,
    },
  },
  background: {
    backgroundRepeat: "repeat",
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  container: {
    position: "relative",
  },
  disable: {
    position: "relative",
    backgroundColor: "#ffffff00",
    border: 0,
    borderRadius: 0,
    overflow: "hidden",
    transition: "transform 150ms ease, box-shadow 100ms ease",
    padding: theme.spacing.xl,
    paddingLeft: theme.spacing.xl,

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: 6,
    },
  },
}));
