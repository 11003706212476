import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { AuthApi } from "./apis";
import { IAuth } from "./interfaces/IAuth";
import ForgotPassword from "./pages/auth/forgot";
import LoginPage from "./pages/auth/login";
import { RegistrationEmailVerify } from "./pages/auth/registration-email-verify";
import { Verify } from "./pages/auth/verify";
import HomePage from "./pages/index";
import { LoginsPage } from "./pages/logins";
import { ProfileMe } from "./pages/me/index";
import { authMe } from "./store/auth";

let __WINDOW_LOADED__ = false;

export function App() {
  const [loading, setLoading] = React.useState(false);
  const { accessToken, sessionScope } = useSelector((state: { auth: IAuth }) => state.auth);

  const ConditionalAccess = ({ children }: any) => {
    const [searchParams] = useSearchParams();

    const hasQueryParams = Array.from(searchParams.keys()).length > 0;

    if (hasQueryParams) {
      return children;
    }

    if (accessToken && sessionScope === "AUTHORIZED") {
      return children;
    }

    return <Navigate to="/login" />;
  };

  const dispatch = useDispatch();

  const init = React.useCallback(async () => {
    setLoading(true);
    if (accessToken) {
      const auth = await AuthApi.me();
      dispatch(authMe(auth));
    }
    setLoading(false);
  }, [accessToken, dispatch]);

  React.useEffect(() => {
    if (!__WINDOW_LOADED__) init();
    __WINDOW_LOADED__ = true;
  }, [init, accessToken]);

  if (loading && !__WINDOW_LOADED__) return <LoadingOverlay visible={loading} />;

  return (
    <Routes>
      <Route>
        <Route
          path="/auth/profile/dan"
          element={
            <ConditionalAccess>
              <ProfileMe />
            </ConditionalAccess>
          }
        />
        {accessToken && sessionScope === "AUTHORIZED" ? (
          <>
            <Route path="/" element={<HomePage />} />
            <Route path="/banks" element={<LoginsPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/verify/:id" element={<Verify />} />
            <Route path="/registration/:id" element={<RegistrationEmailVerify />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Route>
    </Routes>
  );
}
