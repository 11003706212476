import { Badge, Button, Divider, Flex, Grid, LoadingOverlay, Text } from "@mantine/core";
import { IconCheck, IconLock, IconSettings, IconX } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { AuthApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { message } from "../../utils/message";
import { Form } from "../form";
import { DatePickerField } from "../form/datepicker-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";

type IFormData = {
  registerNo: string | undefined;
  familyName: string | undefined;
  lastName: string | undefined;
  firstName: string | undefined;
  phone: string | undefined;
  phone2: string | undefined;
  email: string | undefined;
  email2: string | undefined;
  socialFbLink: string | undefined;
};

const schema = yup.object({
  familyName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна!")
    .required("Заавал бөглөнө!")
    .trim(),
  firstName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна!")
    .required("Заавал бөглөнө!")
    .trim(),
  lastName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна!")
    .required("Заавал бөглөнө!")
    .trim(),
  registerNo: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё]{2}[0-9]{8}$/, "Зөвхөн регистер авна!")
    .typeError("Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .trim(),
  phone: yup
    .string()
    .matches(/^[9|8|6]{1}[0-9]{7}$/, "Дугаарын формат буруу байна!")
    .required("Заавал бөглөнө!")
    .trim(),
  phone2: yup
    .string()
    .transform((value) => (value ? value : null))
    .matches(/^[9|8|6]{1}[0-9]{7}$/, "Зөвхөн дугаар оруулна уу!")
    .nullable()
    .optional()
    .trim(),
  email: yup.string().typeError("Заавал бөглөнө!").email("Цахим шуудан байна!").required("Заавал бөглөнө!").trim(),
  email2: yup.string().email("Цахим шуудан байна!").nullable().optional().trim(),
});

export function MeForm({ onClose, action, reload, changePassword, isDanVerified }: any) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const { departmentUnits, employeeUnits } = useSelector((state: { general: IGeneral }) => state.general);
  const { user } = useSelector((state: { auth: any }) => state.auth);

  const [data] = React.useState<IFormData>({
    registerNo: undefined,
    familyName: undefined,
    lastName: undefined,
    firstName: undefined,
    phone: undefined,
    phone2: undefined,
    email: undefined,
    email2: undefined,
    socialFbLink: undefined,
    ...user,
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);

    try {
      await AuthApi.updateProfile({
        registerNo: values.registerNo,
        familyName: values.familyName,
        lastName: values.lastName,
        firstName: values.firstName,
        phone: values.phone,
        phone2: values.phone2,
        email: values.email,
        email2: values.email2,
      });

      message.success("Таны хүсэлт амжилттай!");

      setIsEdit(false);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message || "Хүсэлт амжилтгүй!");
    }
  };

  const dan = async () => {
    try {
      const res = await AuthApi.dan();
      window.location.href = res.url;
    } catch (error: any) {
      message.error(error?.message || "Хүсэлт амжилтгүй.");
    }
  };

  return (
    <div>
      <Form initialValues={{ ...data }} validationSchema={schema} onSubmit={onSubmit}>
        {({ values }) => {
          return (
            <Grid sx={{ position: "relative" }}>
              <LoadingOverlay visible={loading} />
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField disabled name="username" placeholder="Хэрэглэгчийн нэр" label="Хэрэглэгчийн нэр" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <DatePickerField disabled name="createdAt" placeholder="Бүртгэсэн огноо" label="Бүртгэсэн огноо" nullable />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField disabled={!isEdit} name="registerNo" placeholder="Ажилтны регистр №" label="Ажилтны регистр №" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField disabled={!isEdit} name="familyName" placeholder="Ажилтны ургийн овог" label="Ажилтны ургийн овог" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField disabled={!isEdit} name="lastName" placeholder="Ажилтны эцэг эхийн нэр" label="Ажилтны эцэг эхийн нэр" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField disabled={!isEdit} name="firstName" placeholder="Ажилтны өөрийн нэр" label="Ажилтны өөрийн нэр" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <SelectField
                  options={departmentUnits?.map((c: any) => ({ label: c?.name, value: c?.id }))}
                  disabled
                  name="departmentUnitId"
                  placeholder="Харьяалах нэгжийн нэр"
                  label="Харьяалах нэгжийн нэр"
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <SelectField
                  options={employeeUnits
                    ?.filter((c: any) => c?.departmentUnitId === values?.departmentUnitId)
                    ?.map((c: any) => ({ label: c?.name, value: c?.id }))}
                  disabled
                  name="employeeUnitId"
                  placeholder="Албан тушаалын нэр"
                  label="Албан тушаалын нэр"
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ХОЛБОО БАРИХ" />
                </Grid.Col>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField disabled={!isEdit} name="phone" placeholder="Утасны дугаар №1" label="Утасны дугаар №1" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField disabled={!isEdit} name="phone2" placeholder="Утасны дугаар №2" label="Утасны дугаар №2" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField disabled={!isEdit} name="email" placeholder="И-мэйл хаяг №1" label="И-мэйл хаяг №1" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField disabled={!isEdit} name="email2" placeholder="И-мэйл хаяг №2" label="И-мэйл хаяг №2" />
              </Grid.Col>
              <Grid.Col span={12}>
                <Flex justify="end" gap={8}>
                  {isEdit && (
                    <Button variant="outline" color="violet" onClick={() => setIsEdit(false)} leftSection={<IconX size={20} />}>
                      Болих
                    </Button>
                  )}
                  {isEdit ? (
                    <div>
                      <Button type="submit" color="violet" leftSection={<IconCheck size={20} />} loading={loading}>
                        Хадгалах
                      </Button>
                    </div>
                  ) : (
                    <Button
                      disabled={values?.isDanVerified || false}
                      color="violet"
                      onClick={() => setIsEdit(true)}
                      variant="outline"
                      leftSection={<IconSettings size={20} />}>
                      Мэдээлэл засах
                    </Button>
                  )}
                </Flex>
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="НУУЦЛАЛЫН ТОХИРГОО" />
                </Grid.Col>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Flex direction="column" gap="sm">
                  <Text fw={500} c="gray">
                    Нууц үг үүсгэсэн эсэх
                  </Text>
                  <Badge w={140} h={30} variant="light" size="md" radius="md" color={values?.hasPassword ? "teal" : "red"}>
                    {values?.hasPassword ? "Үүсгэсэн" : "Үүсгээгүй"}
                  </Badge>
                  <Button w={180} onClick={() => changePassword([true])} variant="outline" color="violet" leftSection={<IconLock size={20} />} mt={20}>
                    {values?.hasPassword ? " Нууц үг солих" : " Нууц үг үүсгэх"}
                  </Button>
                </Flex>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Flex direction="column" gap="sm">
                  <Text fw={500} c="gray">
                    ПИН Код үүсгэсэн эсэх
                  </Text>
                  <Badge w={140} h={30} variant="light" size="md" radius="md" color={values?.hasPin ? "teal" : "red"}>
                    {values?.hasPin ? "Үүсгэсэн" : "Үүсгээгүй"}
                  </Badge>
                  <Button w={180} variant="outline" onClick={() => changePassword([true, "PIN"])} color="violet" leftSection={<IconLock size={20} />} mt={20}>
                    {values?.hasPin ? " ПИН Код солих" : " ПИН Код үүсгэх"}
                  </Button>
                </Flex>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Flex direction="column" gap="sm">
                  <Text fw={500} c="gray">
                    ДАН систем баталгаажсан эсэх
                  </Text>
                  <Badge w={140} h={30} variant="light" size="md" radius="md" color={isDanVerified ? "teal" : "red"}>
                    {isDanVerified ? "Тйим" : "Үгүй"}
                  </Badge>
                  {!isDanVerified && (
                    <Button w={180} variant="outline" onClick={() => dan()} color="violet" leftSection={<IconLock size={20} />} mt={20}>
                      ДАН батлах
                    </Button>
                  )}
                </Flex>
              </Grid.Col>
            </Grid>
          );
        }}
      </Form>
    </div>
  );
}
